import styles from './index.module.scss'
import logo from 'static/images/Logo.png'
import { useHistory } from 'react-router-dom'
import { useEffect, useState, useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { UnorderedListOutlined, CloseOutlined } from '@ant-design/icons'
import {
  Drawer,
  MenuList,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MY_WEBSITE } from 'config'

const WHHeader = () => {
  const [scrollDeviation, setScrollDeviation] = useState(0)

  const history = useHistory()

  const isConnect = useMemo(() => {
    return history?.location?.pathname == '/contact'
  }, [history?.location?.pathname])
  const isAbout = useMemo(() => {
    return history?.location?.pathname == '/about'
  }, [history?.location?.pathname])
  const isProducts = useMemo(() => {
    return history?.location?.pathname == '/products'
  }, [history?.location?.pathname])

  // const navigate = useNavigate()
  const { t, i18n } = useTranslation()
  const handleScroll = (e) => {
    setScrollDeviation(document.documentElement.scrollTop || document.body.scrollTop)
  }

  const jumpHome = () => {
    history.push('/home')
  }

  const jumpProduct = () => {
    history.push('/products')
  }

  const jumpMock = () => {
    window.open(t('links.hsk-mock'))
  }
  const jumpCourses = () => {
    window.open(t('links.hsk-course'))
  }
  const jumpStudyInChina = () => {
    // window.open('http://www.baidu.com')
  }
  const jumpForSchool = () => {
    history.push('/products')
  }

  // zh cn
  const saveLangToLocal = (lang) => {
    localStorage.setItem('wohui:lang', lang)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const bgblack = useMemo(() => {
    const isHome = history?.location?.pathname == '/home'
    return (scrollDeviation > 70 && isHome) || !isHome
  }, [scrollDeviation, history?.location?.pathname])

  const aboutUs = () => {
    // console.log(history)
    history.push('/about')
  }
  const connectUs = () => {
    history.push('/contact')
  }

  const products = () => {
    history.push('/products')
  }
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  // const MobileDrawer = () => {
  //   const [open, setOpen] = useState(false)
  //   const showDrawer = () => {
  //     setOpen(true)
  //   }
  //   const onClose = () => {
  //     setOpen(false)
  //   }
  //   return (
  //     <>
  //       <UnorderedListOutlined className='icon-style' onClick={showDrawer}/>
  //       <Drawer
  //         title='Basic Drawer'
  //         placement='right'
  //         closable={false}
  //         onClose={onClose}
  //         open={open}
  //         getContainer={false}
  //         style={{ position: 'absolute' }}
  //       >
  //         <p>Some contents...</p>
  //       </Drawer>
  //     </>
  //   )
  // }
  const useStyles = makeStyles({
    drawerPaper: {
      backgroundColor: '#b43a5e', // 设置背景颜色
    },
  })
  const MobileDrawer = () => {
    const classes = useStyles()
    const [drawerVisible, setDrawerVisible] = useState(false)
    return (
      <>
        {drawerVisible ? (
          <CloseOutlined className='icon-style' onClick={() => setDrawerVisible(drawerVisible)} />
        ) : (
          <UnorderedListOutlined
            className='icon-style'
            onClick={() => setDrawerVisible(!drawerVisible)}
          />
        )}

        <Drawer
          className='mobile-drawer'
          anchor={'right'}
          open={drawerVisible}
          onClose={() => setDrawerVisible(false)}
          classes={{ paper: classes.drawerPaper }}
        >
          <div
            className='tab-list'
            onClick={() => {
              setDrawerVisible(false)
            }}
          >
            <div
              className={`tab-item no-border ${isProducts ? 'active' : ''}`}
              onClick={() => {
                setTimeout(() => {
                  products()
                }, 500)
              }}
            >
              {t('header.products')}
            </div>
            <div>
              <MenuList classes={{ root: 'mobile-sub-menu' }}>
                <MenuItem onClick={jumpMock}>{t('header.hsk-mock')}</MenuItem>
                <MenuItem onClick={jumpCourses}>{t('header.hsk-course')}</MenuItem>
                <MenuItem onClick={jumpStudyInChina} >{t('header.hsk-study-in-china')}</MenuItem>
                <MenuItem onClick={jumpForSchool}>{t('header.for-school')}</MenuItem>
              </MenuList>
            </div>
            <div
              className={`tab-item ${isAbout ? 'active' : ''}`}
              onClick={() => {
                setTimeout(() => {
                  aboutUs()
                }, 500)
              }}
            >
              {t('header.about_us')}
            </div>
            <div
              className={`tab-item ${isConnect ? 'active' : ''}`}
              onClick={() => {
                setTimeout(() => {
                  connectUs()
                }, 500)
              }}
            >
              {t('header.connect')}
            </div>
            <div
              className='tab-item language'
              onClick={() => {
                setTimeout(() => {
                  i18n.changeLanguage(i18n.language == 'en' ? 'zh' : 'en')
                  saveLangToLocal(i18n.language)
                  // jumpMyWebsite(i18n.language == 'en' ? 'zh' : 'en')
                }, 500)
              }}
            >
              {i18n.language == 'en' ? '中文' : 'EN'}
            </div>
          </div>
        </Drawer>
      </>
    )
  }

  const ProductPopover = () => {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null)

    const doOpen = (e) => {
      setOpen((prevOpen) => !prevOpen)

      anchorRef.current.children[0].className = 'tab active'
    }

    const doClose = (event) => {
      if (anchorRef.current && anchorRef.current.contains(event.target)) {
        return
      }
      setOpen(false)
      anchorRef.current.children[0].className = 'tab'
    }

    const handleMouseLeave = (event) => {
      if (
        anchorRef.current &&
        event.relatedTarget &&
        event.relatedTarget instanceof Node &&
        !anchorRef.current.contains(event.relatedTarget) &&
        !event.relatedTarget?.closest('.popper-content')
      ) {
        setOpen(false)
        anchorRef.current.children[0].className = 'tab'
      }
    }

    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault()
        setOpen(false)
        anchorRef.current.children[0].className = 'tab'
      }
    }

    const prevOpen = useRef(open)
    useEffect(() => {
      if (prevOpen.current === true && open === false) {
        anchorRef.current.focus()
      }

      prevOpen.current = open
    }, [open])

    return (
      <>
        <div
          ref={anchorRef}
          className='m-r-28 sub-tab'
          onMouseEnter={doOpen}
          onMouseLeave={handleMouseLeave}
          onClick={jumpProduct}
        >
          <div className={`tab ${isProducts ? 'active' : ''}`}>{t('header.products')}</div>
        </div>

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          placement='bottom-start'
          role={undefined}
          transition
          className='popper-content'
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper classes={{ root: 'menu-dropdown' }}>
                <ClickAwayListener onClickAway={doClose}>
                  <MenuList
                    autoFocusItem={false}
                    id='menu-list-grow'
                    onKeyDown={handleListKeyDown}
                    onMouseLeave={doClose}
                  >
                    <MenuItem onClick={jumpMock}>{t('header.hsk-mock')}</MenuItem>
                    <MenuItem onClick={jumpCourses}>{t('header.hsk-course')}</MenuItem>
                    <MenuItem disabled onClick={jumpStudyInChina}>{t('header.hsk-study-in-china')}</MenuItem>
                    <MenuItem onClick={jumpForSchool}>{t('header.for-school')}</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    )
  }

  const LanguageSelector = () => {
    return (
      <>
        <div
          className='language tab'
          onClick={() => {
            i18n.changeLanguage(i18n.language == 'en' ? 'zh' : 'en')
            saveLangToLocal(i18n.language)
          }}
        >
          {i18n.language == 'en' ? '中文' : 'ENGLISH'}
        </div>
      </>
    )
  }

  return (
    <div className={styles.styles}>
      <div className='header-main'>
        <img src={logo} alt='logo' onClick={jumpHome} />
        {isMobile ? (
          <MobileDrawer></MobileDrawer>
        ) : (
          <div className='tools'>
            <ProductPopover />
            <div className={`m-r-28 tab ${isAbout ? 'active' : ''}`} onClick={aboutUs}>
              {t('header.about_us')}
            </div>
            <div className={`m-r-28 tab ${isConnect ? 'active' : ''}`} onClick={connectUs}>
              {t('header.connect')}
            </div>

            <LanguageSelector />
          </div>
        )}
      </div>
    </div>
  )
}

export default WHHeader
