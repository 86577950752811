import styles from './index.module.scss'
import logo from 'static/images/Logo.png'

import { useHistory } from 'react-router-dom'
import { useMemo, useEffect } from 'react'
import { useList } from 'react-use'
import { useTranslation } from 'react-i18next'
import InIcon from 'static/images/in.png'
import FbIcon from 'static/images/facebook.png'
import YoutubeIcon from 'static/images/youtube.png'
import InsIcon from 'static/images/instagram.png'

const WHFooter = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const isHome = useMemo(() => {
    return history?.location?.pathname == '/home'
  }, [history])
  const isConnect = useMemo(() => {
    return history?.location?.pathname == '/contact'
  }, [history])
  const isAbout = useMemo(() => {
    return history?.location?.pathname == '/about'
  }, [history])
  const isProducts = useMemo(() => {
    return history?.location?.pathname == '/products'
  }, [history])


  const [linkList, setLinkList] = useList([]);

  useEffect(() => {
    setLinkList.set([
      {
        icon: InIcon,
        link: 'https://www.linkedin.com/company/wohuimandarin/',
      },
      {
        icon: InsIcon,
        link: 'https://www.instagram.com/wohuimandarin/?hl=en',
      },
      {
        icon: FbIcon,
        link: 'https://www.facebook.com/media/set/?set=a.440329828074337',
      },
      {
        icon: YoutubeIcon,
        link: 'https://www.youtube.com/@wohuimandarin?app=desktop',
      }
    ])
  }, [])

  return (
    <div className={styles.styles}>
      <div className='link'>
        <div className="link-wrapper">
          <div className="contact-us">
            <div className="contact-us-left">
              <img src={logo} alt="logo" />

              <div className="contact-us-text">
                {t('footer.info')}
              </div>

              <div className="links">
                {
                  linkList.map((item, index) => (
                    <a className="link-item" key={index} href={item.link} target="_blank">
                      <img src={item.icon} alt="icon" />
                    </a>
                  ))
                }
              </div>
            </div>

            <div className="menu">
                <a href="/" className={isHome ? 'active' : ''}>{t('footer.home')}</a>
                <a href="/products" className={isProducts ? 'active' : ''}>{t('footer.products')}</a>
                <a href="/about" className={isAbout? 'active' : ''}>{t('footer.about')}</a>
                <a href="/contact" className={isConnect ? 'active' : ''}>{t('footer.contact')}</a>
            </div>
          </div>

          <div className='copyright'>
            {t('footer.copyright')}
          </div>
        </div>

      </div>


    </div>
  )
}

export default WHFooter
