import { Redirect, Route, Switch } from 'react-router-dom'
import { lazy, Suspense } from 'react'

const Home = lazy(() => import('views/Home'))
const About = lazy(() => import('views/About'))
const Connect = lazy(() => import('views/Connect'))
const AddHskSchool = lazy(() => import('views/AddHskSchool'))
const AddTeachSchool = lazy(() => import('views/AddTeachSchool'))
const Buy = lazy(() => import('views/Buy'))
const Products = lazy(() => import('views/Products'))

const Routes = () => {
  return (
    <Suspense fallback={null}>
      <Switch>
        {/* 首页 */}
        <Route path='/home' component={Home} />
        <Route path='/about' component={About} />
        <Route path='/contact' component={Connect} />
        <Route path='/addHskSchool' component={AddHskSchool} />
        <Route path='/addTeachSchool' component={AddTeachSchool} />
        <Route path='/buy' component={Buy} />
        <Route path='/products' component={Products} />

        <Route exact path='*' fallback='/home' component={() => <Redirect to='/home' />} />
      </Switch>
    </Suspense>
  )
}
export default Routes
